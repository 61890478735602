import { h } from 'preact';
import * as iconData from '../icons.json';

const GiveMenu = ({ isNavOpen, data, handleClose }) => {
  if (!isNavOpen || !data) return null;

  const handleMenuClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="give-nav" onClick={handleMenuClick}>
      <div>
        <h2>{data.title || 'Give'}</h2>
      </div>
      <div>
        <ul>
          {data.children &&
            data.children.map((section, index) =>
              Array.isArray(section) ? (
                section.map((link, idx) => (
                  <li key={`${index}-${idx}`}>
                    <a href={link.href}>{link.title}</a>
                  </li>
                ))
              ) : (
                <li key={index}>
                  <h4>{section}</h4>
                </li>
              )
            )}
        </ul>
      </div>
      <div className="close-button">
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}
          aria-label="Close"
        >
          <div dangerouslySetInnerHTML={{ __html: iconData.close.innerHTML }} />
        </button>
      </div>
    </div>
  );
};

export default GiveMenu;
