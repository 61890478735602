import axios from 'axios';
import { normalizeRockData } from './location-utils';

export const fetchHeaderData = async (url) => {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getRockUser = async (rockId) => {
  try {
    const {
      data: { data: userData }
    } = await axios.get(`${process.env.NEXT_PUBLIC_CRDS_SITE_DOMAIN}api/getCurrentPerson`, {
      params: { accessToken: rockId },
      headers: { 'Content-Type': 'application/json' }
    });

    return normalizeRockData(userData);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const storeUserData = (userData) => {
  const {
    user: { Id, Email },
    campus: { Name }
  } = userData;
  localStorage.setItem('RockUserID', Id);
  localStorage.setItem('RockUserEmail', Email);
  localStorage.setItem('RockUserLocation', Name);
};

export const getCurrentURL = () => {
  const currentUrl = window.location.href;
  return removeTrailingSlash(currentUrl);
};

export const isDateInRange = (dateStart, dateEnd) => {
  const nowIso = new Date().toISOString();

  if (!dateStart || isNaN(new Date(dateStart).getTime())) {
    console.error(`Invalid start date: ${dateStart}`);
    return false;
  }

  const startIso = new Date(dateStart).toISOString();

  if (dateEnd) {
    if (isNaN(new Date(dateEnd).getTime())) {
      console.error(`Invalid end date: ${dateEnd}`);
      return false;
    }
    const endIso = new Date(dateEnd).toISOString();
    return startIso < nowIso && endIso > nowIso;
  }

  return startIso < nowIso;
};

export const removeTrailingSlash = (url) => {
  return url.endsWith('/') ? url.slice(0, -1) : url;
};

export const removeQueryParams = (url) => {
  const queryStringIndex = url.indexOf('?');
  return queryStringIndex !== -1 ? url.substring(0, queryStringIndex) : url;
};

export const isAudienceCorrect = (targetAudience, userSiteName, user) => {
  const isChurchwide = targetAudience?.includes('Churchwide') || targetAudience == null;
  const userTargetAudience = user
    ? userSiteName && targetAudience?.includes(userSiteName)
    : isChurchwide;
  return isChurchwide || userTargetAudience;
};
