const POSSIBLE_LOCATIONS = [
  'Dayton',
  'Lexington',
  'Columbus',
  'Oakley',
  'Mason',
  'Anywhere',
  'East Side',
  'West Side',
  'Florence',
  'Georgetown'
];

const DEFAULT_CAMPUS = {
  IsSystem: false,
  Name: 'Anywhere',
  Description: '',
  IsActive: true,
  ShortCode: 'ANW',
  Url: '',
  LocationId: 22,
  PhoneNumber: '',
  LeaderPersonAliasId: null,
  ServiceTimes: 'Premieres weekly at^8:30AM EST on Sundays on crossroads.net/watch.',
  Order: 0,
  TimeZoneId: '',
  CampusStatusValueId: 765,
  CampusTypeValueId: 767,
  TeamGroupId: 115,
  Location: null,
  LeaderPersonAlias: null,
  CampusStatusValue: null,
  CampusTypeValue: null,
  TeamGroup: null,
  CampusSchedules: [],
  CampusTopics: [],
  CreatedDateTime: '2022-07-05T11:09:20.88',
  ModifiedDateTime: '2023-10-26T10:05:28.937',
  CreatedByPersonAliasId: 13,
  ModifiedByPersonAliasId: 25238,
  ModifiedAuditValuesAlreadyUpdated: false,
  Attributes: null,
  AttributeValues: null,
  Id: 12,
  IdKey: 'JP31EDVpKE',
  Guid: 'e10350db-7ffd-434c-bad5-d3a19f5104e0',
  ForeignId: 15,
  ForeignGuid: null,
  ForeignKey: 'MPImport'
};

const DEFAULT_LOCATION = {
  IsNamedLocation: true,
  GeoFenceCoordinates: null,
  Latitude: 39.15911,
  Longitude: -84.42043,
  ParentLocationId: null,
  Name: 'Anywhere',
  IsActive: true,
  LocationTypeValueId: 181,
  GeoPoint: {
    Geography: {
      CoordinateSystemId: 4326,
      WellKnownText: 'POINT (-84.42043 39.15911)'
    }
  },
  GeoFence: null,
  Street1: '3500 Madison Rd',
  Street2: '',
  City: 'Cincinnati',
  County: 'Hamilton',
  State: 'OH',
  Country: 'US',
  PostalCode: '45209-1120',
  Barcode: '452091120006',
  AssessorParcelId: null,
  StandardizeAttemptedDateTime: '2022-07-02T03:00:09.513',
  StandardizeAttemptedServiceType: 'Smarty Streets',
  StandardizeAttemptedResult: 'RecordType:S; DPV MatchCode:Y; Precision:Zip9',
  StandardizedDateTime: '2022-07-02T03:00:09.513',
  GeocodeAttemptedDateTime: '2022-07-02T03:00:09.513',
  GeocodeAttemptedServiceType: 'Smarty Streets',
  GeocodeAttemptedResult: 'RecordType:S; DPV MatchCode:Y; Precision:Zip9',
  GeocodedDateTime: '2022-07-02T03:00:09.513',
  IsGeoPointLocked: false,
  PrinterDeviceId: null,
  ImageId: null,
  SoftRoomThreshold: null,
  FirmRoomThreshold: null,
  LocationTypeValue: null,
  ChildLocations: [],
  PrinterDevice: null,
  Image: {
    url: 'https://images.ctfassets.net/y3a9myzsdjan/7kzwR219SHf633S4QejEPV/b402ef9ebca90d4b4892315e8bb8b6ea/anywhere-groups.jpg'
  },
  Distance: 0,
  CreatedDateTime: '2022-07-01T17:11:22.527',
  ModifiedDateTime: '2022-10-21T02:04:46.837',
  CreatedByPersonAliasId: 13,
  ModifiedByPersonAliasId: 13,
  ModifiedAuditValuesAlreadyUpdated: false,
  Attributes: null,
  AttributeValues: null,
  Id: 22,
  IdKey: 'GR4ZPG10b9',
  Guid: '6f44e05f-8ebc-4242-b2b9-81d29b07c22f',
  ForeignId: null,
  ForeignGuid: null,
  ForeignKey: null
};

const setLocation = (location) => {
  if (!POSSIBLE_LOCATIONS.includes(location?.Name)) {
    return DEFAULT_LOCATION;
  }
  return location;
};

const setCampus = (campus) => {
  if (!POSSIBLE_LOCATIONS.includes(campus?.Name)) {
    return DEFAULT_CAMPUS;
  }
  return campus;
};

export const normalizeRockData = (userInfo) => {
  const campus = setCampus(userInfo?.campus);
  const location = setLocation(userInfo?.location);

  return { ...userInfo, campus, location };
};
