export const mapSearchResultFields = (hit) => {
  const { contentTypeID, fields, linkedFrom } = hit;

  const getParentSlug = () => {
    if (Array.isArray(linkedFrom) && linkedFrom.length > 0) {
      return linkedFrom[0]?.fields?.slug || '';
    } else if (linkedFrom?.fields?.slug) {
      return linkedFrom.fields?.slug;
    }
    return '';
  };

  const removeLeadingSlash = (url) => {
    return url?.startsWith('/') ? url?.substring(1) : url;
  };

  const fieldMapping = {
    article: {
      title: fields?.title,
      description: fields?.lead_text,
      imageUrl: fields?.imageUrl,
      url: `${process.env.JEKYLL_SITE_DOMAIN}media/articles/${fields?.slug}`,
      category: contentTypeID
    },
    album: {
      title: fields?.title,
      description: fields?.description,
      imageUrl: fields?.backgroundImageUrl,
      url: `${process.env.CRDS_MUSIC_SITE_DOMAIN}music/${fields?.slug}`,
      category: contentTypeID
    },
    author: {
      title: fields?.full_name,
      description: fields?.summary || fields?.host_description,
      imageUrl: fields?.imageUrl,
      url: `${process.env.JEKYLL_SITE_DOMAIN}media/authors/${fields?.slug}`,
      category: contentTypeID
    },
    song: {
      title: fields?.title,
      description: fields?.description,
      imageUrl: fields?.backgroundImageUrl,
      url: `${process.env.CRDS_MUSIC_SITE_DOMAIN}music/${getParentSlug()}/${fields?.slug}`,
      category: contentTypeID
    },
    page: {
      title: fields?.title,
      description: fields?.summary || fields?.description,
      url: `${process.env.JEKYLL_SITE_DOMAIN}${removeLeadingSlash(fields?.permalink)}`,
      category: contentTypeID
    },
    podcast: {
      title: fields?.title,
      description: fields?.description,
      imageUrl: fields?.imageUrl,
      url: `${process.env.JEKYLL_SITE_DOMAIN}media/podcasts/${fields?.slug}`,
      category: contentTypeID
    },
    episode: {
      title: fields?.title,
      description: fields?.description,
      imageUrl: fields?.imageUrl,
      url: `${process.env.JEKYLL_SITE_DOMAIN}media/podcasts/${getParentSlug()}/${fields?.slug}`,
      category: contentTypeID
    },
    video: {
      title: fields?.title,
      description: fields?.description,
      imageUrl: fields?.imageUrl,
      url: fields?.slug,
      category: contentTypeID
    },
    message: {
      title: fields?.title,
      description: fields?.summary || fields?.description,
      imageUrl: fields?.imageUrl,
      url: `${process.env.JEKYLL_SITE_DOMAIN}media/series/${getParentSlug()}/${fields?.slug}`,
      category: contentTypeID
    },
    series: {
      title: fields?.title,
      description: fields?.description,
      imageUrl: fields?.imageUrl,
      url: `${process.env.JEKYLL_SITE_DOMAIN}media/series/${fields?.slug}`,
      category: contentTypeID
    },
    default: {
      title: fields?.title || fields?.full_name,
      description: fields?.description || fields?.summary,
      imageUrl: fields?.imageUrl,
      url: fields?.slug,
      category: contentTypeID
    }
  };

  return fieldMapping[contentTypeID] || fieldMapping.default;
};
