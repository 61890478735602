import { h } from 'preact';
import register from 'preact-custom-element';
import './styles/fonts.css';
import './styles/global-styles.css';
import Button from './components/Button/Button.jsx';
import SearchModal from './components/SearchModal/SearchModal.jsx';
import SearchHit from './components/SearchHit/SearchHit.jsx';
import SharedHeader from './components/SharedHeader/SharedHeader.jsx';

// Fetch data and initialize components
const initializeComponents = async () => {
  try {
    register(
      (props) => h(Button, { ...props, onClick: window.openSearchModal }),
      'test-button',
      ['label', 'url', 'onClick', 'disabled'],
      { shadow: true }
    );

    // Register web components
    register((props) => h(SharedHeader, props), 'shared-header', [], {
      shadow: true
    });
    register(SearchModal, 'search-modal', ['isOpen', 'onClose'], { shadow: true });
    register(SearchHit, 'search-hit', ['title', 'category', 'body', 'url', 'image'], {
      shadow: true
    });

    console.log('Components initialized successfully');
  } catch (error) {
    console.error('Error initializing components:', error);
  }
};

initializeComponents();
