import { h } from 'preact';
import styles from './SearchHit.styles.css';
import ShadowWrapper from '../ShadowWrapper/ShadowWrapper.jsx';
import classNames from 'classnames';
import { truncate } from '../../lib/utils';
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true
});

const SearchHit = (props) => {
  let { theme = 'page', title, category, body, url, image } = props;

  const classes = classNames('container', {
    ['theme_modal']: theme === 'modal',
    ['theme_page']: theme === 'page',
    ['no_border']: theme === 'page',
    ['with_image']: !!image,
    ['page_with_image']: theme === 'page' && !!image
  });

  const renderBody = () => {
    if (!body || body === 'undefined') {
      return null;
    }
    const parsedBody = md.render(body);
    if (theme === 'modal') {
      return (
        <p
          className={styles.body}
          dangerouslySetInnerHTML={{ __html: truncate(parsedBody, 180) }}
        />
      );
    } else if (theme === 'page') {
      return <p className={styles.body} dangerouslySetInnerHTML={{ __html: parsedBody }} />;
    }
  };

  const searchHitElement = (
    <a href={url} className={classes}>
      <div className="content">
        {theme === 'modal' && <span className={styles.category}>{category}</span>}
        <h2 className={theme === 'page' ? 'page-title' : 'modal-title'}>{title}</h2>
        {theme === 'page' && <span className={styles.category}>{category}</span>}
        {renderBody()}
      </div>
      {image && (
        <div className="image_container">
          <img src={image} alt={title} />
        </div>
      )}
    </a>
  );
  return <ShadowWrapper styles={styles}>{searchHitElement}</ShadowWrapper>;
};

export default SearchHit;
