import { h } from 'preact';
import { useEffect, useMemo } from 'preact/hooks';

const NavigationMenu = ({
  navState,
  headerData,
  handleMenuClick,
  handleBackClick,
  getBackgroundClass
}) => {
  useEffect(() => {
    if (navState.openNavName === 'main-nav') {
      document.body.classList.add('nav-open');
    } else {
      document.body.classList.remove('nav-open');
    }
    return () => document.body.classList.remove('nav-open');
  }, [navState.openNavName]);

  const formatAutomationId = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-').replace(/,/g, '');
  };

  const decodeHtmlEntities = (input) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  };

  const decodedPromos = useMemo(() => {
    return headerData && headerData.promos ? decodeHtmlEntities(headerData.promos) : '';
  }, [headerData]);

  const renderSubNav = (item) => (
    <div className="subnavigation active">
      <a href="#" className="back" onClick={handleBackClick}>
        <span>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fal"
            data-icon="chevron-left"
            className="svg-inline--fa fa-chevron-left fa-w-8"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 512"
          >
            <path
              fill="currentColor"
              d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"
            ></path>
          </svg>
        </span>
        Back
      </a>
      <h2>{item.title}</h2>
      {item.children &&
        item.children.map((section, index) =>
          typeof section === 'string' ? (
            <h4 key={index}>{section}</h4>
          ) : (
            <ul key={index}>
              {section.map((child, idx) => (
                <li key={idx} className={child.top_level ? 'top-level' : ''}>
                  <a href={child.href} data-automation-id={child['automation-id']}>
                    {child.title}
                  </a>
                </li>
              ))}
            </ul>
          )
        )}
    </div>
  );

  const renderNavMenu = () => {
    if (!headerData || !headerData.nav) return null;

    return (
      <nav
        className={`${navState.openNavName === 'main-nav' ? 'is-showing' : ''} ${
          navState.activeMenu === 'nested' && navState.nestedContent
            ? getBackgroundClass(navState.nestedContent.title)
            : ''
        }`}
      >
        <div className="content">
          <div className={`navigation ${navState.activeMenu === 'nested' ? 'active' : ''}`}>
            <ul>
              {headerData.nav.map((item, index) => (
                <div key={index} className="hydrated">
                  <li>
                    <a
                      className={navState.activeNavItem === item.title ? 'active' : ''}
                      data-automation-id={`sh-section-${formatAutomationId(item.title)}`}
                      onClick={() => handleMenuClick(item)}
                    >
                      <h2>{item.title}</h2>
                      <p>{item.description}</p>
                    </a>
                  </li>
                </div>
              ))}
            </ul>
          </div>
          {navState.activeMenu === 'nested' &&
            navState.nestedContent &&
            renderSubNav(navState.nestedContent)}

          {decodedPromos && (
            <div className="ctas" dangerouslySetInnerHTML={{ __html: decodedPromos }} />
          )}
        </div>
      </nav>
    );
  };

  return renderNavMenu();
};

export default NavigationMenu;
