import { h, Fragment } from 'preact';

const ShadowWrapper = ({ children, styles }) => {
  return (
    <Fragment>
      <style>{styles}</style>
      {children}
    </Fragment>
  );
};

export default ShadowWrapper;
