/**
 * Truncate text by the number of characters without cutting off a word.
 * @param {string} text - The text to be truncated.
 * @param {number} charLimit - The number of characters to keep.
 * @returns {string} - The truncated text.
 */
export const truncate = (text, charLimit) => {
  if (text.length <= charLimit) {
    return text;
  }

  let truncatedText = text.slice(0, charLimit);

  // Ensure we do not cut off a word
  if (text[charLimit] !== ' ' && text[charLimit - 1] !== ' ') {
    const lastSpaceIndex = truncatedText.lastIndexOf(' ');
    if (lastSpaceIndex !== -1) {
      truncatedText = truncatedText.slice(0, lastSpaceIndex);
    }
  }

  return truncatedText + '...';
};

/**
 * Get the value of a cookie by its key.
 * @param {string} key - The key of the cookie.
 * @returns {string|null} - The value of the cookie, or null if not found.
 */
export const getCookieByKey = (key) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${key}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

/**
 * Check if the device is mobile based on window size.
 * @param {number} [windowSize] - The window size to check against (optional).
 * @returns {boolean} - True if the device is mobile, false otherwise.
 */
export const isMobile = (windowSize) => {
  if (windowSize) return windowSize <= 768;
  return window.matchMedia(`(max-width: 768px)`).matches;
};

/**
 * Get the subdomain based on the environment.
 * @param {string} env - The environment name.
 * @returns {string} - The corresponding subdomain.
 */
export const getSubdomain = (env) => {
  const subdomainMap = {
    demo: 'demo',
    rock: 'my',
    rockdev: 'mydev',
    development: 'int',
    prod: 'www',
    production: 'www'
  };
  return subdomainMap[env] || env;
};

/**
 * Parameterize a string (convert to lowercase and replace non-alphabetic characters with hyphens).
 * @param {string} str - The string to be parameterized.
 * @returns {string} - The parameterized string.
 */
export const parameterize = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-z]/g, '-')
    .replace(/-{2,}/g, '-');
};
