import { h, render } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import styles from './SearchModal.styles.css';
import ShadowWrapper from '../ShadowWrapper/ShadowWrapper.jsx';
import { mapSearchResultFields } from './utils';

const SearchModal = ({ isOpen, onClose }) => {
  const [algoliaReady, setAlgoliaReady] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const searchBoxRef = useRef(null);
  const hitsRef = useRef(null);

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
      document.head.appendChild(script);
    });
  };

  const handleSubmit = (query) => {
    if (query) {
      window.location.href = `https://demo.crossroads.net/search?query=${encodeURIComponent(query)}`;
    }
  };

  useEffect(() => {
    const loadAlgoliaScripts = async () => {
      try {
        await loadScript(
          'https://cdn.jsdelivr.net/npm/algoliasearch@4/dist/algoliasearch-lite.umd.js'
        );
        await loadScript('https://cdn.jsdelivr.net/npm/instantsearch.js@4');
        setAlgoliaReady(true);
      } catch (error) {
        console.error(error);
      }
    };

    loadAlgoliaScripts();
  }, []);

  useEffect(() => {
    if (isOpen && algoliaReady) {
      document.body.style.overflow = 'hidden';

      const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
          onClose();
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      const searchClient = window.algoliasearch(
        process.env.ALGOLIA_APP_ID,
        process.env.ALGOLIA_API_KEY
      );

      const search = window.instantsearch({
        indexName: process.env.ALGOLIA_INDEX_NAME,
        searchClient,
        searchFunction(helper) {
          const query = helper.state.query;
          if (query) {
            helper.search();
            setResultsVisible(true);
          } else {
            setResultsVisible(false);
            if (hitsRef.current) {
              hitsRef.current.innerHTML = '';
            }
          }
        }
      });

      search.addWidgets([
        window.instantsearch.widgets.searchBox({
          container: searchBoxRef.current,
          placeholder: 'Search service times, the latest message, groups and more...',
          showReset: true,
          showSubmit: true,
          showLoadingIndicator: false,
          queryHook(query, search) {
            if (query) {
              search(query);
            } else {
              setResultsVisible(false);
              if (hitsRef.current) {
                hitsRef.current.innerHTML = '';
              }
            }
          }
        }),
        window.instantsearch.widgets.hits({
          container: hitsRef.current,
          transformItems(items, { results }) {
            if (results.query === '') return [];
            return items.slice(0, 8);
          },
          templates: {
            empty: ({ query }, { html }) => {
              return html`<div className="no-results">
                Whoops, we can't find any results matching <strong>${query}</strong>.
              </div>`;
            },
            item: (hit) => {
              const mappedFields = mapSearchResultFields(hit);
              const { title, description, imageUrl, url, category } = mappedFields;
              return `
                <search-hit
                  theme='modal'
                  title='${title}'
                  body='${description}'
                  ${imageUrl ? `image='${imageUrl}'` : ''}
                  category='${category}'
                  url='${url}'
                ></search-hit>
              `;
            }
          }
        })
      ]);

      search.on('render', () => {
        const searchBoxElement = searchBoxRef.current.querySelector('.ais-SearchBox-form');
        if (searchBoxElement) {
          searchBoxElement.onsubmit = (e) => {
            e.preventDefault();
            const query = searchBoxElement.querySelector('.ais-SearchBox-input').value;
            handleSubmit(query);
          };
        }
      });

      search.start();

      if (searchBoxRef.current) {
        const searchBoxInput = searchBoxRef.current.querySelector('.ais-SearchBox-input');
        if (searchBoxInput) {
          searchBoxInput.focus();
        }
      }

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        search.dispose();
        document.body.style.overflow = '';
      };
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen, algoliaReady, onClose]);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    algoliaReady && (
      <ShadowWrapper styles={styles}>
        <div className="overlay" onClick={handleOverlayClick}>
          <div className="modal">
            <div
              ref={searchBoxRef}
              className={`input-container ${resultsVisible ? 'results-visible' : ''}`}
            >
              <button className="cancel" onClick={onClose}>
                Cancel
              </button>
            </div>
            {resultsVisible && <div className="results-banner">RESULTS</div>}
            <div ref={hitsRef} className="results-container"></div>
          </div>
        </div>
      </ShadowWrapper>
    )
  );
};

// Function to mock the modal
window.openSearchModal = () => {
  const container = document.createElement('div');
  document.body.appendChild(container);

  const closeModal = () => {
    render(null, container);
    document.body.removeChild(container);
  };

  render(<SearchModal isOpen={true} onClose={closeModal} />, container);
};

export default SearchModal;
